import React, { useEffect } from "react";
import "./App.css";
// import useScanner from "./scanner/useScanner";
import useScanner from "./scanner/useScanner";
import { Scan } from "./scanner/barcode-parser";

function App() {
  // Test the parser

  // const scan = useScanner();
  const scanner = useScanner({ indicator: "*~" });
  const [scans, setScans] = React.useState<Scan[]>([]);

  useEffect(() => {
    console.log(scanner);
    if (scanner != null) {
      setScans((prevScan) => [...prevScan, scanner]);
    }
  }, [scanner]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {scans.map((scan, index) => {
        return (
          <div key={index} style={{ display: "flex", flexDirection: "row" }}>
            <h4>{scan.type}</h4>
            <h4 style={{ marginLeft: "10px", color: "red" }}>{scan.value}</h4>
          </div>
        );
      })}
    </div>
  );
}

export default App;
