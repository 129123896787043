type BarcodeType = "location" | "freight snap" | "forklift" | "unknown";

export interface Scan {
  type: BarcodeType;
  value: string;
}

// Order matters (first match returned)
const regexParserArray: { type: BarcodeType; regex: RegExp }[] = [
  {
    type: "location",
    regex: /^[ABDRHTCF]\*\w+/,
  },
  {
    type: "freight snap",
    regex: /^FS\*\w+/,
  },
  {
    type: "forklift",
    regex: /^FL\*\w+/,
  },
];

export const parseBarcode = (barcode: string): Scan => {
  const result = regexParserArray.find((parser) => parser.regex.test(barcode));
  if (result) {
    return {
      type: result.type,
      value: barcode,
    };
  }

  //  If no match, return unknown
  return {
    type: "unknown",
    value: barcode,
  };
};
