import { useEffect, useRef, useState } from "react";
import { Scan, parseBarcode } from "./barcode-parser";

interface useScannerProps {
  indicator: string;
  timeOut?: number;
}

const useScanner = ({ indicator, timeOut = 500 }: useScannerProps) => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState<Scan>();
  const lastKeyInputTime = useRef<number>();

  const reset = () => {
    setInput("");
    lastKeyInputTime.current = undefined;
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const { key } = event;
      const inputIsBlank = input === "";
      const keyIsFIC = key === indicator[0];

      // If last input was over 10ms ago, reset
      if (
        lastKeyInputTime.current != null &&
        Date.now() - lastKeyInputTime.current > timeOut
      ) {
        reset();
      }

      // Ignore input until it starts with first indicator character (FIC)
      if (inputIsBlank && !keyIsFIC) {
        return;
      }

      lastKeyInputTime.current = Date.now();

      // If FIC is pressed, start listening for the next key
      if (inputIsBlank && keyIsFIC) {
        setInput(key);
        return;
      }

      // Key = next indicator char, else reset
      if (key === indicator[input.length]) {
        setInput(input + key);
        return;
      } else if (input.length < indicator.length) {
        reset();
      }

      //   Update the input
      if (input.startsWith(indicator) && input.length >= indicator.length) {
        setInput((prevInput) => {
          const newInput = prevInput + key;

          //   If Input is done
          if (
            newInput.endsWith(indicator) &&
            newInput.length >= indicator.length + 1
          ) {
            const scan = parseBarcode(
              newInput.slice(indicator.length, -indicator.length)
            );
            reset();
            setOutput(scan);
            return "";
          }
          return newInput;
        });
      }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [input, indicator, timeOut]);

  return output;
};

export default useScanner;
